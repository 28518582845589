import { useRef, useEffect, useState } from 'react';
import * as faceapi from "face-api.js";

function ExpressionDetection() {
  const [startVideo, setStartVideo] = useState(false);
  const videoRef = useRef();
  const canvasRef = useRef();
  const width = window.screen.width > 480 ? 800 : 350;
  const height = window.screen.width > 480 ? 600 : 260;

  useEffect(() => {

    let detectActive = true;
    if (startVideo) {
      const loadModels = () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
          faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
          faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
          faceapi.nets.faceExpressionNet.loadFromUri('/models'),
          faceapi.nets.ageGenderNet.loadFromUri('/models'),
        ]).then(() => {
          faceDetection();
        })
      };
  
      const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((currentStream) => {
            videoRef.current.srcObject = currentStream;
          })
          .catch((err) => {
            console.error(err)
          });
      }
  
      const faceDetection = async () => {
        const detectionInterval = setInterval(async () => {
          detectActive ? detect() : clearInterval(detectionInterval) ;
      }, 1000);
      }
  
      const detect = async () => {
        
          const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withAgeAndGender();
          
          canvasRef.current.innerHtml = faceapi.createCanvasFromMedia(videoRef.current);
          faceapi.matchDimensions(canvasRef.current, {
            width: width,
            height: height,
          })
  
          const resized = faceapi.resizeResults(detections, {
            width: width,
            height: height,
          });
  
          faceapi.draw.drawDetections(canvasRef.current, resized)
          faceapi.draw.drawFaceLandmarks(canvasRef.current, resized)
          faceapi.draw.drawFaceExpressions(canvasRef.current, resized)
          resized.forEach( detection => {
            const box = detection.detection.box
            const drawBox = new faceapi.draw.DrawBox(box, { label: Math.round(detection.age) + " year old " + detection.gender })
            drawBox.draw(canvasRef.current)
          })
  
        }
  
      startVideo();
      videoRef && loadModels();

    }
   

    return () => {
      detectActive = false;
      try{
        // document.querySelector('canvas').remove();
        videoRef.stream.getTracks().forEach(function(track) {
          track.stop();
        });
      } catch {

      }
      // stopCamera();
    }

  }, [startVideo]);
  
   

  return (
    <div className='flex flex-col justify-center items-center p-6'>
        <div className='flex flex-col justify-center items-center text-white'>
          <h1 className='text-lg font-bold' >Expression Detection System</h1>
        </div>
        {startVideo &&
          <div className='grid grid-rows-1 justify-center items-center'>
            <video
                    ref={videoRef}
                    className="z-0 outline-4 outline-white outline"
                    style={{gridArea: '1 / 1'}}
                    width={width} 
                    height={height}
                    autoPlay
            />
            <canvas ref={canvasRef} className='z-10' style={{gridArea: '1 / 1'}} width={width} height={height} />
            <div className='flex flex-col justify-center items-center text-white m-4'>
                <p>Give a big smile or any other expression.</p>
            </div>
          </div>
        }
        {!startVideo && 
            <div className="flex justify-center items-center">
              <div className="w-full flex flex-col justify-center items-center text-white">
               <p className="mb-6">Press the button below to start the expression detection process.</p>
                <button className="text-white outline outline-white outline-2 p-2" onClick={() => setStartVideo(true)}>
                    CLICK ME
                </button>
            </div>
            </div>
        }
      </div>
  );
}

export default ExpressionDetection;
