import { useState, useEffect } from 'react';
import axios from 'axios';

const Convert = ({ language, text }) => {
	const [translated, setTranslated] = useState('');
	const [debouncedText, setDebouncedText] = useState(text);

	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedText(text);
		}, 500);

		return () => {
			clearTimeout(timerId);
		};

	}, [text]);

	useEffect(() => {
		const doTranslation = async () => {
			const { data } = await axios.post('https://translation.googleapis.com/language/translate/v2', {}, {
				params: {
					q: text,
					target: language,
					key: 'AIzaSyAKlD1I1JlY8bTYBAWchO0NeBECmvjHkZQ'
				}
			});

			setTranslated(data.data.translations[0].translatedText);
		};

	doTranslation();
	
	}, [language, debouncedText]);

	return(
		
		<div className="w-full">
			<p className="label-text-alt text-white">Translated text</p>
			<h1 className="font-bold text-4xl justify-self-start text-left text-white">{translated || '...'}</h1>
		</div>
	);	
};

export default Convert;