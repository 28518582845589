import React, { useEffect, useState } from "react";
import HomeContent from "../components/homecontent";
// import EstateInfo from '../components/location/Location';
import { Team } from "../components/teams/team";
import emailjs from "@emailjs/browser";
import { Position } from "@react-three/drei/helpers/Position";
import { setOpHandler } from "@tensorflow/tfjs-core/dist/tensor";
import { onLinkClicker } from "../layout/navbar";

const Home = () => {
  //Contact us values
  const [conUsername, setConUser] = useState("");
  const [conEmail, setConEmail] = useState("");
  const [conMess, setConMess] = useState("");
  const [showSucc, setShowSucc] = useState(false);
  const [showFail, setShowFail] = useState(false);
  //Join our team values
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [userMotivation, setUserMotivation] = useState("");

  const [error, setError] = useState("");

  const [showRadioVal, setRadioVal] = useState("No rating");
  //templates for the email
  const templateParams = {
    to_name: conUsername,
    to_email: conEmail,
    message: conMess,
    stars: showRadioVal,
  };
  const jointemplateParams = {
    user_name: userName,
    user_surname: userSurname,
    user_email: userEmail,
    user_pos: userPosition,
    user_num: userPhone,
    user_mot: userMotivation,
  };

  const [showTeamModal, setShowTeamModal] = useState(false);

  function onClickSend() {
    const temp = {};

    if (conUsername.length < 3)
      temp["name"] = "Please enter more then 2 characters";

    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (conEmail == "") {
      temp["email"] = "Please enter an email";
    } else if (!conEmail.match(validRegex)) {
      temp["email"] = "Not a valid email address";
    }

    if (conMess.length < 3)
      temp["message"] = "Please enter more then 10 characters";

    if (Object.keys(temp).length > 0) {
      console.log(temp);
      return setError(temp); //stop
    }

    //continue
    emailjs.send(
      "service_6uqfplb",
      "template_jqjgnzj",
      templateParams,
      "enTiVHVk1mtW6GA79"
    );
    setShowSucc(true);
  }

  function onClickJoin() {
    // setShowSucc(false);
    // setShowFail(false);
    const temp1 = {};

    if (userName.length < 3)
      temp1["name2"] = "Please enter more then 2 characters";

    if (userSurname.length < 3)
      temp1["surename"] = "Please enter more then 2 characters";

    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (userEmail == "") {
      temp1["email"] = "Please enter an email";
    } else if (!userEmail.match(validRegex)) {
      temp1["email"] = "Please enter a valid email address";
    }

    if (userPhone.length != 10) temp1["phone"] = "Please enter a valid number";

    if (userPosition.length <= 2)
      temp1["position"] = "Please enter more then 3 characters";

    if (userMotivation.length <= 10)
      temp1["motivation"] = "Please enter more then 10 characters";

    if (Object.keys(temp1).length > 0) {
      console.log(temp1);
      return setError(temp1); //stop
    }

    emailjs.send(
      "service_6uqfplb",
      "template_zw2wdhrw",
      jointemplateParams,
      "enTiVHVk1mtW6GA79"
    );
    setShowSucc(true);
    setShowTeamModal(false);
  }

  return (
    <>
      <div>
        <section
          className="background_stars text-white snap-center"
          id="service"
        >
          <HomeContent title={"SERVICES"}>
            <div className="pb-4 text-center">
              {/* <p className='p-1 text-white'>Learn how we can help you future-proof your business today.</p> */}
              <h2 className="text-xl">DEVELOPMENT</h2>

              <ul className="list-none list-inside">
                <li className="">SOFTWARE DEVELOPMENT</li>
              </ul>
              <ul className="list-inside">
                <li className="md:leading-5 mb-5">
                  Strategic, engaging platform and mobile app development.
                </li>
              </ul>
              <ul className="list-none leading-5 list-inside">
                <li className="">
                  BESPOKE ENTERPRISE RESOURCE MANAGEMENT PLATFORMS
                </li>
              </ul>
              <ul className="list-inside">
                <li className="md:leading-5">
                  For warehousing, logistics and security industries.
                </li>
              </ul>
            </div>
            <br />
            <div className="pb-4 text-center">
              <h2 className="text-xl ">AUTOMATION</h2>

              <ul className="list-none list-inside">
                <li className="md:">BUSINESS PROCESS AUTOMATION</li>
              </ul>
              <ul className=" list-inside">
                <li className="0 md:leading-5 mb-5">Lead generation.</li>
              </ul>
              <ul className="list-none list-inside">
                <li className=" md:">ECOMMERCE AUTOMATION</li>
              </ul>
              <ul className=" list-inside">
                <li className="0 md:leading-5 mb-5">
                  Product information scraping.
                </li>
              </ul>
              <ul className="list-none list-inside">
                <li className="md:">SYSTEM AND HARDWARE AUTOMATION</li>
              </ul>
              <ul className=" list-inside">
                <li className="0 md:leading-5">
                  Facial recognition and computer vision.
                </li>
              </ul>
            </div>
          </HomeContent>
        </section>
        <section className="background_stars text-white snap-center">
          <HomeContent title={"USPs"}>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">EXPERIENCE</li>
              </ul>
              <p className=" leading-5">
                Our 13+ years of experience in the industry has allowed us to
                build a global customer base.
              </p>
            </div>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">BEST TEAM</li>
              </ul>
              <p className=" leading-5">
                We use both top in-house and external talent to provide you with
                competitive value and access to advanced skill sets that you
                won’t find anywhere else.
              </p>
            </div>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">STRICT PROCESSES</li>
              </ul>
              <p className=" leading-5">
                We implement detail-oriented, innovative, but strict processes
                to keep everyone involved on track for guaranteed delivery.
              </p>
            </div>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">KNOWLEDGEABLE</li>
              </ul>
              <p className=" leading-5">
                We become an integral part of your solution because we know the
                right questions to ask.
              </p>
            </div>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">INNOVATIVE APPROACH</li>
              </ul>
              <p className=" leading-5">
                BeOne elevates your business presence and increases your
                performance through innovative strategies.
              </p>
            </div>
            <div className="pb-4 text-center">
              <ul className="list-none list-inside">
                <li className="text-xl">VALUE FOR MONEY</li>
              </ul>
              <p className=" leading-5">
                We find additional business value for you in everything we do.
              </p>
            </div>
          </HomeContent>
        </section>
        <section
          className="background_aboutUs text-white snap-center"
          id="about"
        >
          <HomeContent title={"ABOUT US"}>
            <p className="font-medium text-center mb-3">
              With over 13 years of industry experience, BeOne offers
              exceptional digital services in automation and development. Our
              mission is to solve your problems, empower your business and
              transfer our advanced development skills to you through
              innovative, tailored digital solutions.
              <br />
              <br /> These impactful solutions include business automation,
              artificial intelligence (Al) and computer vision. By partnering
              with BeOne, you’ll receive access to our expert team of strong
              developers, designers, and strategists – all at your disposal.
              BeOne is known for our longevity in the industry and our high
              repeat business statistics. We’re committed to offering quality,
              ensuring outstanding results every time.
              <br />
              <br />
              We founded BeOne with one simple goal in mind – to be the best at
              providing business automation, software development, and robotic
              solutions. BeOne is about the unity that allows our team to
              innovate, create, and deliver winning digital growth solutions. We
              believe in future-proofing your business by doing the best. Are
              you ready to create your future with us?
            </p>
          </HomeContent>
        </section>
        <section className="background_coreValues text-white snap-center font-medium">
          <HomeContent title={"CORE VALUES"}>
            <ul className="list-none list-inside text-center">
              <li className="text-xl">INNOVATION</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              Supports continued growth and improvement.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">EXCELLENCE</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We consistently deliver outstanding service through innovative
              solutions.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">ACCOUNTABILITY</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We take personal ownership and pride in our actions and always put
              forth our best efforts.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">INTEGRITY</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We do not compromise on our standards of integrity and ethical
              conduct.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">UNIQUE</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We go the distance to find a tailored business solution that works
              for you.
            </p>
          </HomeContent>
        </section>
        <section className="background_goals text-white snap-center font-medium">
          <HomeContent title={"GOALS"}>
            <ul className="list-none list-inside text-center">
              <li className="text-xl text-center">PRESENCE</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We want to help you build your online presence through digital
              business solutions tailored to your business needs.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">PERFORMANCE</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We strive to get you results by applying our advanced development
              skills to your business problems.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">INNOVATION</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We aim to give you a competitive edge through innovative digital
              growth solutions.
            </p>
            <ul className="list-none list-inside">
              <li className="text-xl text-center">OUR MISSION</li>
            </ul>
            <p className="mb-5 leading-5 text-center">
              We supercharge your performance through innovative digital growth
              solutions, thereby maximizing operational efficiencies and
              enabling you to scale both rapidly and sustainably.
            </p>
          </HomeContent>
        </section>
        <section
          className="background_automationSolutions text-white snap-center"
          id="dev"
        >
          <HomeContent title={"AUTOMATION SOLUTIONS"}>
            <p className="text-xl text-center">Business Process Automation </p>
            <ul className="list-disc list-inside">
              <li className="mb-5 leading-5 text-center">
                E.g., Lead generation
              </li>
            </ul>
            <p className="text-xl text-center">eCommerce Automation</p>
            <ul className="list-disc list-inside">
              <li className=" mb-5 leading-5 text-center">
                E.g., Product information scraping
              </li>
            </ul>
            <p className="text-xl text-center">
              Scraping and Content Management Automation
            </p>
            <ul className="list-disc list-inside">
              <li className=" mb-5 leading-5 text-center">
                E.g., Digital marketing management
              </li>
            </ul>
            <p className="text-xl text-center">
              Image Processing and Recognition
            </p>
            <ul className="list-disc list-inside">
              <li className=" mb-5 leading-5 text-center">
                E.g., Facial recognition
              </li>
            </ul>
            <p className="text-xl text-center">System automation</p>
            <ul className="list-disc list-inside">
              <li className=" mb-5 leading-5 text-center">
                E.g., Computer vision
              </li>
            </ul>
            <p className="text-xl text-center">Hardware automation</p>
            <ul className="list-disc list-inside">
              <li className=" mb-5 leading-5 text-center">
                E.g., Medical devices
              </li>
            </ul>
          </HomeContent>
        </section>
        {/* <section className='background_redtrans text-white snap-center'>
            <HomeContent title={'Development Solutions'}>
            <p className='text-xl'>Software Development</p>
            <ul className='list-disc list-inside'>
                <li className='md:pl-6 leading-5'>Strategic, engaging platform, and mobile app development.</li>
            </ul>
            <p className='text-xl'>Bespoke Enterprise Resource Management Platforms</p> 
            <ul className='list-disc list-inside'>
                <li className='md:pl-6 leading-5'>For warehousing, logistics, and security industries.</li>
            </ul>
                    
            </HomeContent>
        </section> */}
        <section
          className="background_roboticsSolutions text-white snap-center"
          id="robotics"
        >
          <HomeContent title={"ROBOTICS SOLUTIONS"}>
            <p className="text-center">
              The speed of modern intelligence and technology is rapidly
              transforming our future. That’s why Oxzen Robotics is a
              combination of effective operating systems and tireless
              companions. With the support of a sufficient Warehouse Management
              System and the right team, Oxzen robotics can increase production
              within your distribution in the most autonomous, safe, and secure
              way possible. Get ready to enter the new era of robotic-guided
              vehicles.
            </p>
            {/* <p> For more information about Oxzen, <a className=' global-link'  href="https://oxzendev.restrivedev.co.za/about-us/" target="_blank">"Click on me" </a>or visit <p className='italic'>https://oxzendev.restrivedev.co.za</p></p> */}
          </HomeContent>
        </section>
      </div>
      {/* <section>
        <EstateInfo />
    </section> */}
      <section className="bg-transparent">
        <div className="container px-6  mx-auto text-white">
          <Team />
        </div>
      </section>
      <section className="container md:flex md:justify-between px-6 mx-auto min-h-5%">
        <div className="container px-6 mx-auto  text-center">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-medium leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto uppercase text-center text-white">
            What our clients have to say
          </h2>
          <div className="">
            <div className="md:flex ">
              <div className="row flex-1">
                <div className="column">
                  <div className="  card">
                    <p className="font-light ">
                      It’s always a pleasure to work with BeOne. We have worked
                      with them on a few projects, and they are excellent at
                      offering solutions to non-standard issues. We highly
                      recommend the team.{" "}
                    </p>
                    <h3 className="font-semibold">Sally Thornton </h3>
                    <p className="italic">
                      South Africa Alpha Capital Management{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row flex-1">
                <div className="column">
                  <div className="card">
                    <p className="font-light">
                      BeOne was able to build a smart form on my eCommerce
                      website which solved a major shipping problem. I would
                      highly recommend them.
                    </p>
                    <h3 className="font-semibold">Rikus Neethling </h3>
                    <p className="italic">Bizoe Wines</p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="row flex-1">
                <div className="column">
                  <div className="card">
                    <p className="font-light">
                      I have been doing business with BeOne for over 6 years,
                      and it has been the greatest pleasure. Not often do you
                      find a company that cares about your business just as much
                      as you do yourself.{" "}
                    </p>
                    <h3 className="font-semibold">Dana Viljoen </h3>
                    <p className="italic">Gibbor Security and Lusanele </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="id='detail w-full max-w-2xl px-6 py-4 mx-auto background_redtrans rounded-md shadow-md dark:bg-gray-800"
        id="quote"
      >
        <h2 className="text-3xl font-medium text-center text-white">
          GET IN TOUCH
        </h2>
        <p className="mt-3 text-center text-white">
          Please fill in the form, and we’ll get back to you soon. We can’t wait
          to help you find the best digital growth solutions for your business.
        </p>

        <div className="text-white grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
          <a
            href="https://www.google.com/maps/place/11+Rembrandt+St,+Petervale,+Sandton,+2191/@-26.0367854,28.0435604,17z/data=!3m1!4b1!4m5!3m4!1s0x1e9573c2c805ef75:0xb792c16896aa453a!8m2!3d-26.0367854!4d28.0435604"
            target="_blank"
            class="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:background_edit"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="mt-2 text-center">
              11 Rembrandt Street, Petervale, Sandton, 2191
            </span>
          </a>

          <a
            href="tel:0100130089"
            className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:background_edit"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>

            <span className="mt-2 text-center">(+27)10 013 0089</span>
          </a>

          <a
            href="mailto:admin@beone.ai"
            className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md dark:text-gray-200 hover:background_edit"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>

            <span className="mt-2 text-center">admin@beone.ai</span>
          </a>
        </div>

        <div className="mt-6 ">
          <div className="items-center  -mx-2 md:flex">
            <div className="w-full md:mr-2 ">
              <label className="block mb-2 ml-2 text-sm font-medium  text-white">
                Name
              </label>
              <input
                value={conUsername}
                onClick={() => setError({ ...error, name: "" })}
                onChange={(e) => setConUser(e.target.value)}
                type="text"
                className="block w-full px-4 py-2 background_edit border rounded-md  text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="E.g., John Doe"
              />
              {error?.name && <span className="text-red">{error.name}</span>}
            </div>

            <div className="w-full  md:mt-0">
              <label className="block mb-2  ml-2  text-sm font-medium  text-white">
                E-mail
              </label>
              <input
                value={conEmail}
                onClick={() => setError({ ...error, email: "" })}
                onChange={(e) => setConEmail(e.target.value)}
                type="email"
                className="block w-full px-4 py-2 background_edit border rounded-md text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="E.g., John@gmail.com"
              />
              {error?.email && <span className="text-red">{error.email}</span>}
            </div>
          </div>
          <div className="items-center -mx-2 md:flex ">
            <div className=" w-full   md:mt-0">
              <label className="block mb-2 ml-2 mt-2 text-sm font-medium  text-white">
                Message
              </label>
              <textarea
                value={conMess}
                onClick={() => setError({ ...error, message: "" })}
                onChange={(e) => setConMess(e.target.value)}
                type="text"
                className="block w-full h-40 px-4 py-2 background_edit border rounded-md text-white dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                placeholder="E.g., I love BeOne"
              ></textarea>
              {error?.message && (
                <span className="text-red">{error.message}</span>
              )}
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button
              className="px-4 py-2 text-white transition-colors duration-200 background_edit rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mt-4"
              onClick={onClickSend}
            >
              Send Message
            </button>
          </div>
          <div className=" my-8 text-center">
            <div>
              <p className="font-medium text-2xl text-white m-2">Thank You </p>
              <div className="text-white">
                <p>We want to be awesome! We work with passion and heart.</p>
                <p>
                  If you like our work, please rate 5 stars. It helps so much!
                </p>
                {/* <p className='font-medium text-2xl text-white m-2'>What do you think about us? Feel free to rate us. </p>
                        <div className='text-white'>
                            <p>We want to be awesome! Thanks for helping us get there.</p>
                            <p>All information is confidential and only the rating is required.</p> */}
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="rate">
                <input
                  type="radio"
                  id="star5"
                  name="rate"
                  value={showRadioVal}
                  onClick={() => setRadioVal(5)}
                />
                <label htmlFor="star5" title="Awesome">
                  5 stars
                </label>
                <input
                  type="radio"
                  id="star4"
                  name="rate"
                  value={showRadioVal}
                  onClick={() => setRadioVal(4)}
                />
                <label htmlFor="star4" title="Good">
                  4 stars
                </label>
                <input
                  type="radio"
                  id="star3"
                  name="rate"
                  value={showRadioVal}
                  onClick={() => setRadioVal(3)}
                />
                <label htmlFor="star3" title="Neutral">
                  3 stars
                </label>
                <input
                  type="radio"
                  id="star2"
                  name="rate"
                  value={showRadioVal}
                  onClick={() => setRadioVal(2)}
                />
                <label htmlFor="star2" title="Okay">
                  2 stars
                </label>
                <input
                  type="radio"
                  id="star1"
                  name="rate"
                  value={showRadioVal}
                  onClick={() => setRadioVal(1)}
                />
                <label htmlFor="star1" title="Unhappy">
                  1 star
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="career">
        <div className="px-4 pb-12 pt-1 mx-auto mt-12 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20 text-white">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-medium leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto uppercase text-center">
              Join Our Dynamic Team
            </h2>
          </div>
          <div className="flex flex-col justify-center px-6">
            <p className="text-medium text-center font-light">
              We’re always looking for super talented software developers,
              website designers, digital marketers, copywriters, and IT gurus.
              Join our team and help us provide digital growth solutions for
              businesses across the globe in every industry. Please
              <a
                className="cursor-pointer global-link italic"
                href
                onClick={() => setShowTeamModal(true)}
              >
                {" "}
                fill in the form,
              </a>{" "}
              and we’ll get back to you soon.
            </p>
          </div>
        </div>
      </section>
      {showTeamModal === true ? (
        <section className="fixed right-0 left-0 bottom-0 top-0 max-w-4xl p-6  m-5 background_contact  rounded-md shadow-md dark:bg-gray-800 overflow-y-auto">
          <div>
            <div className="flex flex-row items-center ">
              <div className=" flex w-full justify-center">
                <h2 className=" text-s font-medium md:text-2xl text-white justify-center mr-6">
                  JOIN OUR DYNAMIC TEAM
                </h2>
              </div>

              <div className="flex-1 flex-nowrap w-full justify-end ">
                <button
                  onClick={() => setShowTeamModal(false)}
                  className="text-3xl text-white mb-2.5"
                >
                  x
                </button>
              </div>
            </div>

            <p className="md:text-lg font-light text-gray-700 text-white text-1xs text-center">
              We are so excited to see what you have to offer, but before we
              take this further. Please complete the form accurately and
              honestly.
            </p>

            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 justify-content: center">
              <div>
                <label className="text-white" htmlFor="username">
                  Name
                </label>
                <input
                  value={userName}
                  onClick={() => setError({ ...error, name2: "" })}
                  onChange={(e) => setUserName(e.target.value)}
                  id="username"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  placeholder="E.g., Jane"
                />
                {error?.name2 && (
                  <span className="text-red">{error.name2}</span>
                )}
              </div>

              <div>
                <label className="text-white" for="Surname">
                  Surname
                </label>
                <input
                  value={userSurname}
                  onClick={() => setError({ ...error, surename: "" })}
                  onChange={(e) => setUserSurname(e.target.value)}
                  id="Surename"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  placeholder="E.g., Doe "
                />
                {error?.surename && (
                  <span className="text-red">{error.surename}</span>
                )}
              </div>

              <div>
                <label className="text-white" for="emailAddress">
                  Email
                </label>
                <input
                  value={userEmail}
                  onClick={() => setError({ ...error, email: "" })}
                  onChange={(e) => setUserEmail(e.target.value)}
                  id="emailAddress"
                  type="email"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  placeholder="E.g., Jane@gmail.com"
                />
                {error?.email && (
                  <span className="text-red">{error.email}</span>
                )}{" "}
              </div>
              <div>
                <label className="text-white" for="phone">
                  Phone
                </label>
                <input
                  value={userPhone}
                  onClick={() => setError({ ...error, phone: "" })}
                  onChange={(e) => setUserPhone(e.target.value)}
                  id="phone"
                  type="numbers"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  placeholder="E.g., 072 123 4567"
                />
                {error?.phone && (
                  <span className="text-red">{error.phone}</span>
                )}
              </div>
            </div>
            <div className="mt-6">
              <label className="text-white " for="posistion">
                Position
              </label>
              <input
                value={userPosition}
                onClick={() => setError({ ...error, position: "" })}
                onChange={(e) => setUserPosition(e.target.value)}
                id="posistion"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                placeholder="E.g., Developer "
              />
              {error?.position && (
                <span className="text-red">{error.position}</span>
              )}
            </div>
            <div className="mt-6">
              <label className="text-white mt-4" for="motivation">
                Motivation
              </label>
              <input
                value={userMotivation}
                onClick={() => setError({ ...error, motivation: "" })}
                onChange={(e) => setUserMotivation(e.target.value)}
                id="motivation"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-gray-700 background_edit border border-gray-200 rounded-md dark:bg-gray-800 text-white dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                placeholder="E.g., I aspire to build, grow and up skill myself in the field of tech and know I can bring my skills to better the company's growth"
              />
              {error?.motivation && (
                <span className="text-red">{error.motivation}</span>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4 ">
              <div className="mt-6 ">
                <button
                  className="px-6 py-2 leading-5 text-white transition-colors duration-200 background_edit transform bg-gray-700 rounded-md hover:bg-gray-600 "
                  onClick={() => setShowTeamModal(false)}
                >
                  Back
                </button>
              </div>
              <div className=" mt-6 flex justify-end">
                <button
                  className="px-6 py-2 leading-5 text-white transition-colors duration-200 background_edit transform bg-gray-700 rounded-md hover:bg-gray-600 "
                  onClick={onClickJoin}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <button
          className="text-white invisible "
          onClick={() => setShowTeamModal(true)}
        >
          Show Modal
        </button>
      )}

      {showSucc && (
        <div className="fixed top-0 left-0 flex w-full  overflow-hidden bg-green text-white rounded-lg ">
          <div className="px-4 py-2 -mx-3 flex-1">
            <div className="mx-3">
              <span className="font-semibold">Success</span>
              <p className="flex text-sm text-gray-600 dark:text-gray-200 ">
                Your email has been successfully sent. Thank you
              </p>
            </div>
          </div>
          <div
            onClick={() => setShowSucc(false)}
            className="flex items-center justify-center w-8 cursor-pointer"
          >
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z"
                fillRule="nonzero"
                className="w-6 h-6"
              />
            </svg>
          </div>
        </div>
      )}
      {showFail && (
        <div className="fixed top-0 left-0 flex w-full max-w-sm mx-auto overflow-hidden background_notification rounded-lg shadow-md dark:bg-gray-800">
          <div className="px-4 py-2 -mx-3 flex-1">
            <div className="mx-3">
              <span className="font-semibold">Error</span>
              <p className="flex text-sm text-gray-600 dark:text-gray-200">
                Something went wrong, failed to send email
              </p>
            </div>
          </div>
          <div
            onClick={() => setShowFail(false)}
            className="flex items-center justify-center w-8 cursor-pointer"
          >
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z"
                fillRule="nonzero"
                className="w-6 h-6"
              />
            </svg>
          </div>
        </div>
      )}
      {/* <div className='flex '>
                            <div className='flex-1'>
                                < input type="text" value={username} onChange={e=>setUser(e.target.value)} id='sName' class="medium" placeholder="Name" aria-invalid="false"/>
                                < input type="text" value={surname} onChange={e=>setSurname(e.target.value)} id='sSurname' class="medium" placeholder="Surname" aria-invalid="false"/>
                            </div>
                            <div className='flex-1'>
                                < input type="email" value={email} onChange={e=>setEmail(e.target.value)} id='sEmail' class="medium" placeholder="Email Address" aria-invalid="false"/>
                                < input type="number" value={phone} onChange={e=>setPhone(e.target.value)} id='sPhone' class="medium" placeholder="Phone Number" aria-invalid="false"/>
                            </div>
                            
                        </div> */}
    </>
  );
};

export default Home;
