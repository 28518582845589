import { useEffect, useState } from 'react'
import useChatSession from '../../hooks/useChatSession';



export const UserDetails = ({previousStep, step, steps ,triggerNextStep}) => {
    // const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('user-form-data')) || {})
    const [session, setSession] = useState(JSON.parse(sessionStorage.getItem('current-session')) || {
        sessionID: ('_' + Math.random().toString(36).slice(2, 10)),
        // ip: JSON.parse(localStorage.getItem('user_location')).ip
    })

    const { saveUser } = useChatSession();

    useEffect(() => {
        setSession({...session,  [previousStep.id]: previousStep.value})
        sessionStorage.setItem('current-session', JSON.stringify(session))
    }, [])

    // useEffect(() => {
    //     localStorage.setItem('user-form-data', JSON.stringify(formData));
    //     setSession({...session, ...formData})
    // }, [formData])

    useEffect(() => {
        
        // if (session.hasOwnProperty('email')){
        //     saveUser({
        //         'session_id': session['sessionID'],
        //         'ip': session['ip'],
        //         'email': session['email'],
        //         'first_last_name': session['name-surname'],
        //         'phone_number': session['phone-number'],
        //         'project_type': session['project-type'],
        //         'budget': session['budget'],
        //         'industry': session['industry'],
        //         'company_name': session['company-name'],
        //         'company_size': session['company-size'],
        //         'company_goal': session['company-goal'],
        //         'company_needs': session['company-needs'],
        //         'company_time_frame': session['company-time-frame']
        //     })
        // }  
    }, [])

    useEffect(() => {
        switch(previousStep.id){
            case 'email':
                return triggerNextStep({trigger: 'name-surname'});
            case 'name-surname':
                return triggerNextStep({trigger: 'phone-number'});
            case 'phone-number':
                return triggerNextStep({trigger: 'industry'});
            case 'industry':
                return triggerNextStep({trigger: 'company-name'});
            case 'company-name':
                return triggerNextStep({trigger: 'company-size'});
            case 'company-size':
                return triggerNextStep({trigger: 'company-goal'});
            case 'company-goal':
                return triggerNextStep({trigger: 'company-needs'});
            case 'company-needs':
                return triggerNextStep({trigger: 'company-time-frame'});
            case 'company-time-frame':
                return triggerNextStep({trigger: 'project-type'});
            case 'project-type':
                return triggerNextStep({trigger: 'budget'});
            default:
                return triggerNextStep({trigger: 'end'});
        }
    }, [])

    return <></>
    
    // switch(previousStep.id){
    //     case 'email':
    //         return <>{`Thank you for submitting, What is you name and surname?`}</>
    //     case 'name-surname':
    //         return <>{'What is your number?'}</>
    //     case 'phone-number':
    //         return <>{'What industry are you in?'}</>
    //     case 'industry':
    //         return <>{`Company name?`}</>
    //     case 'company-name':
    //         return <>{`What is your company size?`}</>
    //     case 'company-size':
    //         return <>{`What is your company's main goal currently? (*e.g., grow business, improve operational efficiencies, reduce costs, etc)`}</>
    //     case 'company-goal':
    //         return <>{`What can we help you with? (Please define your specific business need/problem)`}</>
    //     case 'company-needs':
    //         return <>{`What is the timeframe during which you aim to have your problem/need addressed?`}</>
    //     case 'company-time-frame':
    //         return <>{`What type of project do you need?`}</>
    //     case 'project-type':
    //         return <>{`Estimated budget range?`}</>
    //     default:
    //         return <>{'Great, Thank you for your time! We will be in touch. Have a nice day!'}</>
    // }
        
}
