import clients from "./data";

export const Team = () => {

    return (
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-medium leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto uppercase text-center">
              Our trusted clients
          </h2>
        </div>
        <div className="grid gap-8 row-gap-6 mx-auto sm:row-gap-8 lg:max-w-screen-lg sm:max-w-1/2 grid-cols-2 lg:grid-cols-3">
            {clients.map((val, index) => (
                <div className={`flex justify-center ${val?.class}`} key={index}>
                    <img
                    className="object-cover w-20 h-20 mr-4 rounded-full shadow"
                    src={val.logo}
                    alt={val.name}
                    />
                    <div className={`flex flex-col justify-center ${val?.class}`}>
                      <p className="text-lg font-bold">{val.name}</p>
                    </div>
                </div>
            ))}
        </div>
      </div>
    );
  };