import { createChatBotMessage } from 'react-chatbot-kit';
import steps from './steps';

const config = {
  
  initialMessages: [createChatBotMessage(`Hi, Welcome to Beone's automated help service. How can I be of service?`)],
  botName: 'B',
  state:{
    steps:[],
  
  },
  widgets:[
    {
      widgeteName: "steps",
      widgetFunc:(props)=> <steps  {...props}/>,
      mapStateToProps: ["steps"],
         
      
    }
  ]
};

export default config;