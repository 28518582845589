import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Main from "./pages";
import TermsAndConditions from "./pages/terms-and-conditions";

interface Props {
  selectedSlide: number | undefined;
  setSelectedSlide: any;
}

const AppRoutes: React.FC<Props> = ({ selectedSlide, setSelectedSlide }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Main
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
          />
        }
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
