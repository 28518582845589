import React, { useState } from "react";
// import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./approutes";
// import Bg from './components/bg/bg'
import CustomChatBot from "./components/chatbot";
import Footer from "./layout/footer";
import NavBar from "./layout/navbar";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  const [selectedSlide, setSelectedSlide] = useState(1);

  return (
    <>
      <Router>
        {/* <Bg /> */}
        <NavBar setSelectedSlide={setSelectedSlide} />
        <AppRoutes
          selectedSlide={selectedSlide}
          setSelectedSlide={setSelectedSlide}
        />
        <Footer />
        <CustomChatBot />
      </Router>
    </>
  );
};

export default App;
