import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './config.js';
import MessageParser from './messageparser.js';
import ActionProvider from './actionprovider.js';
import { useState } from 'react';

const CustomChatBot = () => {
const [showBot, setShowBot] = useState(false);

  const saveMessages = (message, HTMLString) => {
    sessionStorage.setItem('chat_message', JSON.stringify(message));
  }

  const loadMessages = () => {
    const messages = JSON.parse(sessionStorage.getItem('chat_messages'));
    return messages;
  }

  return (
    <>
    { 
      showBot && 
      <div className='appChatbotContainer' id='chatBotUID'>
      <div className=''>X</div>
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          messageHistory={loadMessages()}
          saveMessages={saveMessages}
        />
      </div>
    }
    
    {/* <button onClick={() => setShowBot(!showBot)} className="appChatbotButton"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="appChatbotButtonIcon"><path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path></svg></button> */}
    </>
  );
};

export default CustomChatBot;