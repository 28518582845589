import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber';
import { CameraControls, Sphere } from './planetrederer'


const HomeContent = ({title, children}) => {
  return (
    <div  className='flex items-center relative'>
        <div className='flex-nowrap container m-2 px-14 md:py-4 mx-auto '>
          <div className='self-start'>
            <h2 className='text-4xl break-words font-medium lg:text-center text-center mb-10'>{title}</h2>
          </div>
          <div className='flex-wrap lg:px-200 lg:text-center font-medium'>
            <span>{children}</span>
          </div>
          {/* <div className='flex-1 h-screen-40 lg:max-h-96 '>
            <Canvas className='cursor-grab active:cursor-grabbing'>
              <CameraControls />
              <directionalLight intensity={1} />
              <ambientLight intensity={0.6} />
              <Suspense fallback="loading">
                <Sphere />
              </Suspense>
            </Canvas>
          </div> */}
        </div>
    </div>
  )
}
/*
<div  className='flex items-center relative'>
        <div className='container m-8 lg:flex lg:justify-between md:mt-12 px-6 md:py-4 mx-auto '>
          <div className='flex-none lg:min-w-64 lg:max-w-64 self-start'>
            <h2 className='text-4xl break-words font-medium lg:text-left md:text-left mb-2'>{title}</h2>
          </div>
          <div className='flex-1 lg:px-5 lg:text-left  font-medium'>
            <span>{children}</span>
          </div>
*/
export default HomeContent;