
import fnb from '../../assets/images/client logos/FNB-Logo.png';
import forgood from '../../assets/images/client logos/forgood-logo.png';
import gibbor from  '../../assets/images/client logos/Gibbor-Logo.png';
import inc from '../../assets//images/client logos/IncAfrica-Logo.png';
import inovio from '../../assets/images/client logos/Inovio-Logo.png';
import konfig from '../../assets/images/client logos/Konfig-Logo.png';
import logictrails from '../../assets/images/client logos/Logictrials-Logo.png';
import mdi from '../../assets/images/client logos/mdi-logo.png';
import mercedes from '../../assets/images/client logos/Merceedes benz logo.png';
import nkulu from '../../assets/images/client logos/Nkulu-Logo.png';
import pod from '../../assets/images/client logos/POD-Logo.png';


const clients = [
    {
        //name: 'First National Bank',
        logo: fnb
    },
    // {
    //     name: 'Forgood',
    //     logo: forgood
    // },
    {
        //name: 'Gibbor',
        logo: gibbor
    },
    // {
    //    // name: 'IncAfrica',
    //     logo: inc
    // },
    {
        //name: 'Inovio',
        logo: inovio
    },
    {
        //name: 'Konfig',
        logo: konfig
    },
    {
        //name: 'Logictrails',
        logo: logictrails
    },
    {
        //name: 'MDI',
        logo: mdi
    },
    {
        //name: 'Mercedes',
        logo: mercedes
    },
    {
        //name: 'Nkulu',
        logo: nkulu
    },
    {
        //name: 'Print On Demand',
        logo: pod,
        class: 'center-client'
    }
]

export default clients;