import React, { useEffect, useRef, useState } from "react";
import * as ml5 from 'ml5'
import Webcam from "react-webcam";
import { drawHand } from "../../utils/handposeutils";

function HandPoseInstance() {
  const [startVideo, setStartVideo] = useState(false);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const width = window.screen.width > 480 ? 800 : 350;
  const height = window.screen.width > 480 ? 600 : 260;

  useEffect(() => {
    let detectHandpose = true;

    if (startVideo){
        const runHandpose = () => {
    
        console.log("Handpose model loaded.");
        //  Loop and detect hands
        const handposeDetection = setInterval(() => {
            detectHandpose ? detect() : clearInterval(handposeDetection)
          }, 100);
        };
    
    
      const handpose = ml5.handpose(webcamRef.current.video, runHandpose)
    
      const detect = () => {
        // Check data is available
        if (
          typeof webcamRef.current !== "undefined" &&
          webcamRef.current !== null &&
          webcamRef.current.video.readyState === 4
        ) {
          // Get Video Properties
          const video = webcamRef.current.video;
    
          // Set video width
          webcamRef.current.video.width = width;
          webcamRef.current.video.height = height;
    
          // Set canvas height and width
          canvasRef.current.width = width;
          canvasRef.current.height = height;
    
          // Draw mesh
          const ctx = canvasRef.current.getContext("2d");
          handpose.on('hand', results => {
              drawHand(results, ctx)
            }
          )
          
        }
      };
    }

    return () => {
      detectHandpose = false;
    }
  }, [startVideo])

  

  return (
    <div className='flex flex-col justify-center items-center p-6'>
    <div className='flex flex-col justify-center items-center text-white'>
        <h1 className='text-lg font-bold' >Handpose Detection System</h1>
    
    </div>
    {startVideo &&
    <div className="grid grid-rows-1 justify-center items-center p-5">
        <Webcam
            ref={webcamRef}
            className="z-0 outline-4 outline-white outline"
            style={{gridArea: '1 / 1'}}
            width={width} 
            height={height}
        />
        <canvas ref={canvasRef} className="z-10" style={{gridArea: '1 / 1'}}/>
        <div className='flex flex-col justify-center items-center text-white m-4'>
                <p>Make peace, show us a peace sign or any other hand posture and watch us detect it</p>
            </div>
    </div>}
    {!startVideo && 
        <div className="flex justify-center items-center">
          <div className="w-full flex flex-col justify-center items-center text-white">
          <p className='mb-6'>Press the button below to start the handpose detection process</p>
            <button className="text-white outline outline-white outline-2 p-2" onClick={() => setStartVideo(true)}>
                CLICK ME
            </button>
            </div>
        </div>
    }
    </div> 
  );
}

export default HandPoseInstance;