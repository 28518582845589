import { useState, useEffect } from 'react'
import ObjectDetector from '../components/objectdetection/objectdetector';
import PlanetSlider from '../layout/planetslider';
import Home from './home';
import Translate from '../components/speech-to-text/Translate';
import ExpressionDetection from '../components/expression-detection/ExpressionDetection';
import HandPoseInstance from '../components/handpose';
import { Helmet } from 'react-helmet';

const Main = ({selectedSlide, setSelectedSlide}) => {
  const [slide, setSlide] = useState(1);

  useEffect(() => {
    setSlide(selectedSlide);

    // alert(selectedSlide)
  }, [selectedSlide]);

  return (
    <>
     <meta property="og:image" content="beone.white.png"></meta>
    <meta property="description" content="This is a fancy website"></meta>
    <meta property="og:url" content="https://www.google.com"></meta>
      <PlanetSlider slide={slide} setSlide={setSlide} />
      {
        slide === 1
        ? 
        <>
          <Helmet>
              <meta charSet="utf-8" />
              <title>BeOne</title>
              <link rel='icon' type="img/png" href='beone_logo.png' sizes='16x16'/>
          </Helmet>
          <Home />
        </>
        
        : slide === 2 
        ? 
        <>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Object Detector - BeOne</title>
              <link rel='icon' type="img/png" href='beone_logo.png' sizes='16x16'/>
          </Helmet>
          <ObjectDetector />
        </>
        
        : slide === 3
        ? 
        <>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Expression Detection - BeOne</title>
            <link rel='icon' type="img/png" href='beone_logo.png' sizes='16x16'/>
          </Helmet>
          <ExpressionDetection />
        </>
        : slide === 4
        ?
        <>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Translation Detection - BeOne</title>
            <link rel='icon' type="img/png" href='beone_logo.png' sizes='16x16'/>
          </Helmet>
          <Translate />
        </> 
        : slide === 5 
        ? 
        <>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Handpose Detection - BeOne</title>
            <link rel='icon' type="img/png" href='beone_logo.png' sizes='16x16'/>
          </Helmet>
          <HandPoseInstance />
        </>
        : <></>
      }
    </>
  )
}

export default Main;