import { rfft } from "@tensorflow/tfjs";
import React, { useEffect, useState } from "react";
// import {Navigate} from 'react-router-dom';
import Dropdown from "../components/general/nav-services-dropdown";
import ObjectDetector from "../components/objectdetection/objectdetector";
import { Link, useNavigate } from "react-router-dom";

const DEMO_LINKS = [
  { link: "#test", text: "Label" },
  { link: "#test", text: "Label" },
];

const NavBar = ({ setSelectedSlide }) => {
  const navigate = useNavigate();
  let el;
  const handleButtonClick = () => {
    let hideElement = document.getElementById("main-title");
    let navLinksContainer = document.getElementById("nav-links-container");
    if (!hideElement.classList.contains("hide-all")) {
      hideElement.classList.add("hide-all");
      navLinksContainer.style.visibility = "visible";
    } else {
      hideElement.classList.remove("hide-all");
      navLinksContainer.style.visibility = "hidden";
    }
  };

  function onLinkClick(idToScrollTo) {
    if (window.location.pathname !== "/") {
      navigate("/");

      setTimeout(() => {
        el = document.querySelector(idToScrollTo);
        el.scrollIntoView({ behavior: "smooth" });

        if (!el) return;
      }, 200);
    } else {
      el = document.querySelector(idToScrollTo);

      if (!el) return;

      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <nav className="navbar m-2">
      <div className="container py-4 mx-auto   md:ml-6 md:flex md:justify-between md:items-center h-24">
        <div className="flex items-center justify-between md:w-96 ">
          <div className="flex justify-center items-center">
            <a
              className="text-2xl font-bold text-gray-800 transition-colors duration-200 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"
              href="/"
            >
              <img
                className="h-24 w-auto m-2 p-3 mx-2"
                src="background_logo.svg.svg"
              />
            </a>
            <a
              className="toggle-button cursor-pointer"
              onClick={handleButtonClick}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </a>
          </div>
        </div>
        <div id="nav-links-container" className="navbar-links font-medium ">
          <div className=" font-medium text-white  m-8 ">
            <div className=" bg-links z-50 md:mr-5">
              <ul
                id="menu-main"
                className="flex flex-col md:flex-row justify-between text-white"
              >
                <li className="navlink m-2">
                  <p
                    className="cursor-pointer "
                    onClick={() => onLinkClick("#about")}
                  >
                    ABOUT
                  </p>
                </li>
                <li className="bg-transparent m-2">
                  <div className="dropdown cursor-pointer">
                    SERVICES
                    <ul className="dropdown-content text-white text-sm">
                      <li className="navlink">
                        <p
                          className="cursor-pointer"
                          onClick={() => onLinkClick("#dev")}
                        >
                          Development & Automation
                        </p>
                      </li>
                      <li>
                        <p
                          className="cursor-pointer"
                          onClick={() => onLinkClick("#robotics")}
                        >
                          Robotics Solutions
                        </p>
                      </li>
                    </ul>
                  </div>
                  {/* </li>
                    <li id="menu-main" className="bg-transparent m-2">
                    <div className='dropdown cursor-pointer'>FEATURES
                        <ul className='dropdown-content text-white text-sm'>
                          <li><p className="cursor-pointer" onClick={() => setSelectedSlide(2)}>Obeject Detection System</p></li>
                          <li><p className="cursor-pointer" onClick={() => setSelectedSlide(3)}>Expression Detection System</p></li>
                          <li><p className="cursor-pointer" onClick={() => setSelectedSlide(4)}>Translation Detection System</p></li>
                          <li><p className="cursor-pointer" onClick={() => setSelectedSlide(5)}>Handpose Detection System</p></li>

                        </ul>
                      </div> */}
                </li>
                <li id="menu-main" className="bg-transparent navlink m-2">
                  <p
                    className="cursor-pointer"
                    onClick={() => onLinkClick("#career")}
                  >
                    CAREERS
                  </p>
                </li>
                <li id="menu-main" className="bg-transparent m-2">
                  <div
                    className="dropdown cursor-pointer"
                    onClick={() => onLinkClick("#quote")}
                  >
                    CONTACT
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
