import {useState, useEffect} from 'react';

import './Planets.css';

const PLANETS = ['mars', 'earth', 'mercury', 'venus', 'jupiter', 'saturn', 'uranus', 'neptune']

export default function PlanetSlider({slide, setSlide}) {
    // const [rerender, setRerender] = useState(true);

    // useEffect(() => {
    //     if(!rerender) {
    //         setRerender(true);
    //     }
    // }, [rerender]);

    useEffect(() => {
        const navLink = document.getElementsByClassName('navlink')
        for(let x = 0; x < navLink.length; x++) {
            navLink[x].addEventListener('click', () => setSlide(1))
        }
        // navLink.forEach(element => {
        //     element.addEventListener('click', () => setSlide(1));
        // });
    }, [])
    function onClicker(idToScrolltTo) {

        const el = document.querySelector(idToScrolltTo);
        if(!el) return;
    
        el.scrollIntoView({behavior: 'smooth'}); 
      }
    
    
    function onPrevClick() {
        if(slide == 1 ){
            setSlide(slide+4);
            onClicker('#testers');
        } else{
            setSlide(slide - 1);
            onClicker('#testers');
        }
    }
    
    function onNextClick() {
        if(slide == 5 ){
           setSlide(1);
           onClicker('#testers');
        }else{
            setSlide(slide + 1);
            onClicker('#testers');
        }
        
    }
   

    return (
        <>
        <div className="container px-6 py-4 mx-auto md:flex-col md:justify-between md:items-center overflow-x-hidden min-h-20 md:min-h-screen">
            <div className="absolute top-0 left-0 h-screen w-screen -z-10">
                <div className='planets'>
                    <div className='solar'>
                            <div className='solar_systm'>
                                <div className={`planet ${PLANETS[slide - 1]}`}>
                                    <div className='planet-overlay '></div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div id='main-title' className="w-full min-h-75vh flex flex-col justify-center cursor-shuttle" style={{minHeight: '40vh'}}>
                <div className="w-full -z-10">
                    <div className=" items-center a-reveal">
                        <p className="text-white tracking-custom font-medium uppercase a-reveal text-center text-sm md:text-base">DEVELOPMENT-FOCUSED, DIGITAL GROWTH SOLUTIONS FOR YOUR BUSINESS</p>
                    </div>
                    <div className="h-4" />
                    <div className="w-full a-reveal text-center">
                        <h2 className="font-medium text-white uppercase tracking-custom-wider text-3xl md:text-6xl ">Automation<br/>Software development<br/>Robotic solutions
                        {/* <span className="text-red tracking-custom uppercase pt-4 inline text-sm md:hidden">new</span> */}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col md:flex-row" style={{minHeight: '26vh'}}>
                <>
                    <div className="flex a-reveal-late items-center text-white">
                        <span className="w-10 border-red border-b-2 mx-4" />
                        <span className="uppercase tracking-custom ml-2 text-xs">BeOne’s business automation, software development, and robotic solutions bring the future to life in your business.</span>
                    </div>
                    <div className="flex a-reveal-later items-center text-white ml-0 md:ml-20 mt-2 md:mt-0">
                        <span className="w-10 border-red border-b-2 mx-4" />
                        <span className="uppercase tracking-custom ml-2 text-xs">Our team functions like a well-oiled machine to innovate, create, and deliver the best digital growth solutions for you.</span>
                    </div>
                    <div className="flex a-reveal-later items-center text-white ml-0 md:ml-20 mt-2 md:mt-0">
                        <span className="w-10 border-red border-b-2 mx-4" />
                        <span className="uppercase tracking-custom ml-2 text-xs">So, if you feel like you’re stuck in the past, the BeOne team is here to help you create your future today. </span>
                    </div>
                </>
            </div>
            <div className="  flex-col sm:m-3 sm:p-2 md:flex-row md:items-center text-white justify-content:center">
                <div className="mt-6 flex justify-center">
                    <div onClick={slide > 0 ? onPrevClick : null}  className= "sm:w-8 sm:h-8 h-10 w-10 md:h-14 md:w-14 lg:h-18 lg:w-18  rounded-full border-2 cursor-pointer flex items-center justify-center text-white hover:text-black transition-custom border-white ">
                    <svg clipRule="evenodd" className='justify-center hover:animate-ping' fill='white' fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22 12.002c0-5.517-4.48-9.997-9.998-9.997-5.517 0-9.997 4.48-9.997 9.997 0 5.518 4.48 9.998 9.997 9.998 5.518 0 9.998-4.48 9.998-9.998zm-1.5 0c0 4.69-3.808 8.498-8.498 8.498s-8.497-3.808-8.497-8.498 3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497zm-6.711-4.845c.141-.108.3-.157.456-.157.389 0 .755.306.755.749v8.501c0 .445-.367.75-.755.75-.157 0-.316-.05-.457-.159-1.554-1.203-4.199-3.252-5.498-4.258-.184-.142-.29-.36-.29-.592 0-.23.107-.449.291-.591zm-.289 7.564v-5.446l-3.522 2.718z" fillRule="nonzero"/></svg>
                    </div>
                   
                    <div className="flex  md:text-4xl text-l items-center cursor-pointer mx-6">
                        <span className="text-red ">0{slide}</span>
                        <span className="w-10 border-white border-b-2 mx-5" />
                        <span className="text-white ">05</span>
                    </div>
                    <div  onClick={slide < 6 ? onNextClick : null} className="sm:w-8 sm:h-8 h-10 w-10 md:h-14 md:w-14 lg:w-18 lg:h-18 rounded-full border-2 cursor-pointer flex items-center justify-center text-white hover:text-black transition-custom border-white">
                    <svg clipRule="evenodd" fill='white' className='justify-center hover:animate-ping' fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.009 12.002c0-5.517 4.48-9.997 9.998-9.997s9.998 4.48 9.998 9.997c0 5.518-4.48 9.998-9.998 9.998s-9.998-4.48-9.998-9.998zm1.5 0c0 4.69 3.808 8.498 8.498 8.498s8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497-8.498 3.807-8.498 8.497zm6.711-4.845c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591zm.289 7.564v-5.446l3.523 2.718z" fillRule="nonzero"/></svg>
                    
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
