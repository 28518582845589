import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import * as ml5 from "ml5";

const ObjectDetector = () => {
    const [startVideo, setStartVideo] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const objectDetector = useRef(null);

    const width = window.screen.width > 480 ? 800 : 350;
    const height = window.screen.width > 480 ? 600 : 260;

    useEffect(() => {
        let detectActive = true;

        if (startVideo){
            const ctx = canvasRef.current.getContext('2d');
            const modelLoaded = () => {
                const detectionInterval = setInterval(() => {
                    detectActive ? detect() : clearInterval(detectionInterval) ;
                }, 2000);
            };

            objectDetector.current = ml5.objectDetector('cocossd', modelLoaded);

            const detect = () => {

                if (videoRef.current.video.readyState !== 4) {
                    return;
                }

                videoRef.current.video.width = width;
                videoRef.current.video.height = height; 
                

                objectDetector.current.detect(videoRef.current?.video, (err, results) => {
                    canvasRef.current.width = width;
                    canvasRef.current.height = height;
                    if (results && results.length) {
                        results.forEach((detection) => {
                            ctx.beginPath();
                            ctx.fillStyle = "#ff0000";
                            ctx.strokeStyle = "white";
                            ctx.rect(detection.x, detection.y, detection.width - 100, detection.height);
                            ctx.stroke();
                            ctx.font = "30px Arial";
                            ctx.fillText(detection.label, detection.x + 10, detection.y + 24);
                        });

                    }
                });
            };
        }
        

        return () => {
            detectActive = false;
        }
    }, [startVideo]);



    return ( 
        <div className='flex flex-col justify-center items-center p-6' id='test' >
            <div className='flex flex-col justify-center items-center text-white '>
                <h1 className='text-lg font-bold text-center' >Object Detection System</h1>
            </div>
            {startVideo &&
            <div className="grid grid-rows-1 justify-center items-center p-5">
                <Webcam
                    ref={videoRef}
                    className="z-0 outline-4 outline-white outline"
                    style={{gridArea: '1 / 1'}}
                    width={width} 
                    height={height}
                />
                <canvas ref={canvasRef} className="z-10" style={{gridArea: '1 / 1'}}/>
                <div className='flex flex-col justify-center items-center text-white m-4'>
                    <p>Place a object in the view of the camera for us to detect.</p>
                </div>
            </div>}
            {!startVideo && 
                <div className="flex justify-center items-center">
                    <div className="w-full flex flex-col justify-center items-center text-white">
                     <p className='mb-6'>Press the button below to start the object detection process.</p>
                    <button className="text-white outline outline-white outline-2 p-2" onClick={() => setStartVideo(true)}>
                        CLICK ME
                    </button>
                    </div>
                </div>
            }
        </div>  
    );
};

export default ObjectDetector; 