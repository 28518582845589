import React from "react";
import HomeContent from "../components/homecontent";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Home from "./home";
// import PlanetSlider from "../layout/planetslider";
import "../layout/planetslider/Planets.css";

const PLANETS = [
  "mars",
  "earth",
  "mercury",
  "venus",
  "jupiter",
  "saturn",
  "uranus",
  "neptune",
];

//This is a component styling the text and the headings.
function StyledComponent({ title, children }) {
  return (
    <div className="container lg:px-32 px-6">
      <h2 className="no-wrap mb-2 text-justify text-2xl font-heavy text-white">
        {title}
      </h2>
      <div className="display: flex text-center">
        <div className="w-full mb-16 text-justify text-white">{children}</div>
      </div>
    </div>
  );
}

//This is for the places where there are 2 headings after one another. This will edit the first main heading.
function StyledSeperateHeading({ title }) {
  return (
    <div className="container lg:px-32 px-6">
      <h2 className="no-wrap mb-1 text-left text-3xl font-heavy text-white">
        {title}
      </h2>
    </div>
  );
}

const TermsAndConditions = () => {
  return (
    <>
      <div className="container px-6 py-4 mx-auto md:flex-col md:justify-between md:items-center overflow-x-hidden min-h-20 md:min-h-screen">
        <div className="absolute top-0 left-0 h-screen w-screen -z-10">
          <div className="planets">
            <div className="solar">
              <div className="solar_systm">
                <div className={`planet ${PLANETS[0]}`}>
                  <div className="planet-overlay "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header terms and conditions start here */}
        <div
          id="main-title"
          className="w-full min-h-75vh flex flex-col justify-center cursor-shuttle"
          style={{ minHeight: "30vh" }}
        >
          <div className="w-full -z-10">
            <div className="h-4" />
            <div className="w-full a-reveal text-center">
              <h2 className="font-medium text-white uppercase tracking-custom-wider text-3xl md:text-6xl ">
                Terms and Conditions
              </h2>
              <br />
            </div>
          </div>
        </div>
        {/* Content starts here */}
        <StyledComponent>
          <p>
            The following terms and conditions are for BEONE AI (PTY) LTD, a
            full-suite digital and software development agency that renders the
            following services: Software development, Application development,
            Digital marketing services, Website design, Website maintenance,
            Hosting, Sales and IT support and consulting services (herein
            referred to as “the Services”).
          </p>
        </StyledComponent>
        <StyledComponent>
          <p>
            The Services provided to the Client are subject to the following
            terms and conditions:
          </p>
        </StyledComponent>
        <StyledComponent title="Bookings and payment">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                After the booking/request is received, the Client will be
                provided with a draft quotation. The draft quotation is valid
                for 7 (seven) days and is subject to a material price increase,
                increases in terms of the law, and any additional work required
                by the Client.
              </li>
              <li>
                All charges are exclusive of VAT, insurance, delivery and
                transportation costs, and the Client shall be charged
                accordingly.
              </li>
              <li>
                By accepting the draft quotation/booking, the Client accepts
                these terms and conditions.
              </li>
              <li>
                It is not necessary for the Client to sign acceptance of these
                terms and conditions for them to apply. If the Client accepts
                the draft quote, the Client will be deemed to have satisfied
                themselves as to the terms applying and have fully accepted
                these terms and conditions.
              </li>
              <li>
                The Client will receive a tax invoice from BEONE AI. Full
                payment of the tax invoice is due and payable with immediate
                effect on receipt of the tax invoice.
              </li>
              <li>
                Payment will be made through electronic fund transfer or
                pre-set-up debit order. The client shall be liable for payment
                of any bank charges incurred due to cash or cheque payments.
              </li>
              <li>
                It is the responsibility of the Client to confirm BEONE AI’s
                bank account details by way of a bank letter and verbal
                communication. Any payments will only be deemed received once
                the funds have been cleared in BEONE AI’s official bank account.
              </li>
              <li>
                BEONE AI will attend to the Client’s premises for the agreed
                period by the Client, prior to or at the commencement of the
                appointment. BEONE AI will endeavour to diagnose and or remedy
                the problem(s) described by the Client at the commencement of
                the appointment. If the problems have not been remedied by the
                end of the first hour of BEONE AI’s attendance, BEONE AI will
                discuss the available options with the Client. The Client may
                ask BEONE AI to continue working on the problem(s) or make
                another appointment. The Client may terminate the appointment at
                any time. On termination, the Client will pay BEONE AI the
                amount incurred in respect of additional time, if any, beyond
                the first hour’s attendance with immediate effect together with
                the original tax invoice. If payment is to be made using a
                pre-set-up debit order, the BEONE AI technician must be supplied
                with a printed copy of the completed transfer. In the event of a
                default or non-payment.
              </li>
              <li>
                BEONE AI will charge a fee of 2% of the outstanding amount per
                month. The fee will be charged from the date that payment
                becomes overdue to and including the date payment is made in
                full.
              </li>
              <li>
                The Client has no right to withhold payment or make set-offs or
                deductions from any payment due by the Client for any reason
                whatsoever. No extension of payment of any nature will be
                granted unless reduced to writing and signed by the Client and a
                duly authorized representative of The Client agrees that a
                certificate provided by BEONE AI at any given time shall be
                sufficient prima facie (at first sight) proof of the facts
                therein stated or the amount stated therein for the purposes of
                all legal proceedings against the Client for the recovery of any
                outstanding amount.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Working hours">
          <p>
            BEONE AI’s normal working hours are Monday to Friday, 08:00 to
            17:00. In some cases, subject to previous arrangements being made,
            the Services will be provided outside of the normal working hours.
            These will include Saturday and/or Sunday and the latest working
            time of 21:00. An overtime fee will be charged for these additional
            working hours. Weeknights and Saturdays will be charged at double
            the normal hourly rate (applies to travel reimbursement as well).
            Sundays will be charged at two and a half times the normal hourly
            rate (applies to travel reimbursement as well).
          </p>
        </StyledComponent>
        <StyledComponent title="Duration of Agreement">
          <p>
            The Client’s monthly service is fixed for a period of 12 months by
            default unless otherwise agreed to in writing. This service will
            automatically renew unless prior written cancellation notice is
            provided two calendar months in advance.
          </p>
        </StyledComponent>
        <StyledComponent title="Courier service">
          <p>
            BEONE AI accepts no responsibility for any courier service providers
            recommended by the Client as these are to be used as a guideline.
            The onus lies on the Client for sign-up and management of their
            chosen service provider. Should any losses be incurred, this will be
            at the sole risk of the Client and BEONE AI will not be liable for
            any losses.
          </p>
        </StyledComponent>
        <StyledSeperateHeading title="All support:" />
        <StyledComponent title="Appointments and rates">
          <p>
            BEONE AI will attend to the Client’s premises at the prearranged
            time. The Client will pay for all services provided, at the rates in
            the most recent Agreement with the Client or charged as per BEONE
            AI’s latest prices.
          </p>
        </StyledComponent>
        <StyledComponent title="Labour">
          <p>
            Any labour and travel carried out by any BEONE AI
            employee/representative will automatically be billed at the standard
            rate in hourly segments if excluded by any existing agreement or if
            no quotation was requested.
          </p>
        </StyledComponent>
        <StyledSeperateHeading title="Services:" />
        <StyledComponent title="Projects">
          <p>
            Projects will only commence once the deposit as per BEONE AI’s draft
            quotation has been paid in full and cleared in BEONE AI’s bank
            account. The deposit will be 50% of the total invoice unless
            otherwise specified. Once the deposit is received, BEONE AI will
            compile a detailed Scope with timelines and payment milestones.
            Final payment in full is required before “Go Live” or the release of
            code. All content/scope must be supplied in writing via our detailed
            scope/brief/web design brief (“the Brief”). Projects can only
            commence once the Brief has been received. BEONE AI will only
            communicate with the appointed representative of the company as per
            the Brief for the duration of the project. No instructions will be
            taken from any other company representative. The person who
            completes the Brief will be the company representative unless
            otherwise stipulated. Any scope, content, images, etc. the Client
            still requires not supplied in the Brief/Scope will be billed at an
            additional cost at the standard hourly rate. Photographic image
            files must be supplied in a high-quality digital format. Each phase
            of the Development/Automation/Web Design process allows for three
            working days approvals from the Client. If no response has been
            received after three working days, this will be seen as an automatic
            acceptance of the Development/Automation/Design and any missing
            information required will be billed at an additional cost to the
            Client. BEONE AI’s normal hourly rate will apply. Any project delays
            caused by the Client will be billed at 10% of the project fee for
            each week of delay. Any requests that need to be actioned after
            BEONE AI’s working hours will be billed at double the hourly rate.
          </p>
        </StyledComponent>
        <StyledComponent title="eCommerce Web design package">
          <p>
            Three example products are included. Any additional products will be
            an additional cost to the Client and will be quoted accordingly. The
            eCommerce Web package consists of the following pages: Home, About,
            Products, and Contact. Additional pages will be billed at the
            standard hourly rate.
          </p>
        </StyledComponent>
        <StyledSeperateHeading title="Logo design:" />
        <StyledComponent title="Go/Standard design package">
          <p>
            One draft design will be provided with no revisions. Should the
            Client require any revisions, this will be an additional cost to the
            Client and will be quoted accordingly.
          </p>
        </StyledComponent>
        <StyledComponent title="Pro design package">
          <p>
            Three draft designs will be provided. Should the Client require any
            further revisions, this will be an additional cost to the Client and
            will be quoted accordingly.
          </p>
        </StyledComponent>
        <StyledComponent title="Social media">
          <p>
            Social media platforms will be managed by BEONE AI only. All
            upcoming events should be communicated a minimum of 14 days prior to
            the event. No content to be loaded by the Client. The Client can
            access their social media accounts at all times.
          </p>
        </StyledComponent>
        <StyledComponent title="Social media content creation">
          <p className="mb-16">
            Content will be created in one-month batches and sent to the Client
            for approval 14 days prior to the next month. Approvals should be
            made within three working days, or content will be scheduled as is.
          </p>
          <p>
            Should the Client require graphics/flyers for specific events, this
            needs to be communicated 14 days prior to the event. The normal
            BEONE AI hourly rate will apply.
          </p>
        </StyledComponent>
        <StyledComponent title="Google Ads">
          <p className="mb-16">
            BEONE AI will endeavour to help the Client obtain their online
            advertising goals by providing the Client with advice, information,
            and technical services in relation to search engine
            advertising/marketing. BEONE AI does not guarantee any particular
            rate of return or performance of any online advertising on Google
            Ads advertising, including but not limited to any particular search
            results page/s or rankings. BEONE AI cannot be held responsible for
            commercial outcomes which are associated with the internet marketing
            or management of the Client’s Google Ads advertising account for the
            Client’s business and/or websites.
          </p>
          <p>
            BEONE AI provides an internet marketing service at a competitive
            price. BEONE AI does not insure or underwrite the Client’s chosen
            business model. The Client acknowledges that internet services are
            inherently subject to technical failures and disruptions from time
            to time. Ownership, risk, and liability
          </p>
        </StyledComponent>
        <StyledComponent title="Ownership, risk, and liability">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                All risk and loss in and to all goods (services rendered
                relating to goods) sold by BEONE AI to the Client shall pass to
                the Client on delivery or collection thereof.
              </li>
              <li>
                Ownership in all goods (services rendered relating to goods)
                sold and delivered or collected shall remain vested in BEONE AI
                until the full purchase price as reflected on the
                quotation/invoice has been paid.
              </li>
              <li>
                In the event of any services or goods which may contain a risk
                and BEONE AI informed the Client of such risk, BEONE AI shall
                only effect commencement of the Services upon receipt of a
                written and signed instruction from the Client that acknowledges
                that the Client was informed of the risk and will not hold BEONE
                AI liable in the event of any damages or loss due to
                commencement of services to the goods which contains such risk.
                It is the responsibility of the Client to determine that the
                services or goods ordered are suitable for the purposes of
                intended use.
              </li>
              <li>
                BEONE AI shall not be liable to the Client for any loss, damage,
                or expense of any nature, whether direct, special, indirect, or
                consequential, including but not limited to loss or profits
                arising out of BEONE AI’s performance or the Client’s use of the
                goods or services rendered.
              </li>
              <li>
                The Client indemnifies and holds BEONE AI (including its
                employees, representatives, subcontractors, agents, or
                subsidiaries) harmless against all claims of whatsoever nature
                that may be brought or threatened against BEONE AI by the Client
                or any third party arising from or in connection with any
                defect, latent or otherwise in any goods supplied and/or
                services rendered by BEONE AI including but not limited to any
                intellectual property rights claims or disputes brought against
                BEONE AI in connection with rendering the Services to the Client
                or supplying any goods to the Client under these terms and
                conditions.
              </li>
              <li>
                Under no circumstances will BEONE AI be liable for any
                consequential damages or for any delictual liability of any
                nature.
              </li>
              <li>
                BEONE AI accepts no liability in respect of any problem(s) it is
                not able to remedy due to any matter beyond its control,
                including but not limited to the age, specification, or
                condition of the Client’s hardware and/or software as well as
                the Client’s failure to provide appropriate software disks,
                drivers, product serial numbers or any fault with the Client’s
                internet service provider.
              </li>
              <li>
                The Client hereby confirms that a full backup of the Client’s
                hard drive has been made prior to BEONE AI commencing the
                Services. The Client further confirms that there is no legal
                restriction or impediment to BEONE AI providing the Services to
                the Client.
              </li>
              <li>
                BEONE AI will take no responsibility for any security loss or
                corruption of data or any disruption of service unless these
                services are explicitly paid for, all accounts are up-to-date,
                and all other terms have been met.
              </li>
              <li>
                BEONE AI will not take any responsibility for missing emails.
              </li>
              <li>
                BEONE AI shall under no circumstances be liable, either in
                contract or otherwise, for any damage or injury caused to the
                Client, its employees, agents, or any third parties. Including,
                without limitation, any direct and or indirect or consequential
                damages, expenses, costs, profits, lost savings, earnings,
                interruption to business activity, lost or corrupted data, or
                other liability arising out of or related to the Services
                provided by BEONE AI or out of installation, de-installation,
                use of, or inability to use the Client’s computer equipment,
                hardware, software or peripherals. The Client will, upon demand,
                indemnify BEONE AI with respect to loss, damage, or injury
                arising from the provision of the Services. BEONE AI has no
                liability to the Client for data loss or damage incurred in any
                circumstances whatsoever.
              </li>
              <li>
                BEONE AI may make recommendations to the Client, or the Client
                may request that a product be provided by BEONE AI in order for
                BEONE AI to perform the Services. All expressed or implied
                warranties, descriptions, representatives, and conditions as to
                the quality or compatibility in respect of the Services, any
                item of software, hardware, or peripheral provided by BEONE AI,
                are expressly excluded. BEONE AI has no liability as to the
                suitability of the performance of the Services nor of any
                product manufactured, sold, or supplied by a third party,
                whether or not that product has been recommended to the Client
                by BEONE AI. Any hardware, software, or equipment provided to
                the Client by BEONE AI shall remain the property of BEONE AI
                until full payment is received. BEONE AI is a distributor only.
                Products sold by BEONE AI are not manufactured by BEONE AI. The
                products may, however, be covered by each manufacturer’s
                warranty service and support policy (if present). BEONE AI
                assigns and passes through to the Client any warranty of the
                manufacturer, and the Client acknowledges that it shall have
                recourse only under such warranties and only against the
                manufacturer of the products unless the Consumer Protection Act
                is applicable. BEONE AI makes no representation or express
                warranty with respect to the products except those stated in
                this document. BEONE AI disclaims all other warranties, express
                or implied, as to any such products, including and without
                limitation, the implied warranties of merchantability and
                fitness for a particular purpose, and any implied warranties
                arising from statute, trade usage, course of dealing, or course
                of performance unless the Consumer Protection Act is applicable.
                All items sold through BEONE AI are sold “as is” or “with all
                faults” unless the Consumer Protection Act is applicable. The
                entire risk as to the quality and performance of these items is
                with the Client. Should any of these items prove defective, not
                function, or function improperly in any way following their
                purchase, the Client, and not the manufacturer, distributor, or
                BEONE AI, assumes the entire cost of all necessary servicing or
                repair. Should the products be covered under warranty, the
                warranty period begins on the date that the products were
                ordered from BEONE AI and not from the date of delivery. This
                also relates to the warranty period of old stock. The Client
                confirms that the specifications and features of the goods are
                correct. A handling fee of 10% in respect of any return process
                will be charged for any goods accepted by the Client and then
                requiring it to be returned based on any expenses incurred by
                BEONE AI.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Delivery or collection">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                BEONE AI does not guarantee that the Services will be rendered
                or goods will be supplied on any particular date and time, and
                the Client shall have no claim against BEONE AI in respect of
                any loss occasioned by any reasonable delay in delivery of any
                goods ordered or services rendered.
              </li>
              <li>
                The Client may not any order by reason of such reasonable delay
                unless the Consumer Protection Act is applicable.
              </li>
              <li>
                The services or goods will only be handed over/delivered to the
                Client once the final payment reflects in BEONE AI’s bank
                account.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Cancellation and Termination">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                The Client (if the client is a Consumer and a natural person)
                may cancel the agreement by way of 20 (twenty) business days’
                written notice to BEONE AI. BEONE AI is entitled to impose a
                reasonable cancellation penalty for cancellation received in
                terms of this clause, and the Client remains liable for any
                amounts still owed to BEONE AI up to the date of cancellation.
              </li>
              <li>
                In all other cases, either Party shall be entitled to terminate
                this Agreement by giving the other party 2 (two) calendar
                months’ written notice of their intention to terminate this
                Agreement. 
              </li>
              <li>
                BEONE AI is entitled to impose a reasonable cancellation penalty
                for cancellation received in terms of this clause, and the
                Client remains liable for any amounts still owed to BEONE AI up
                to the date of cancellation.
              </li>
              <li>
                Nothing in this Agreement prevents the parties to cancel this
                Agreement by mutual agreement between the Parties, subject
                thereto that it shall be reduced in writing and signed by both
                Parties.
              </li>
              <li>
                The acceptance of any refund or returns are at BEONE AI’s sole
                discretion and subject to payment of services already completed.
              </li>
              <li>
                BEONE AI must be notified of any appointment cancellations by no
                later than 4 (four) business hours prior to the appointment.
                BEONE AI reserves the right to invoice the Client when
                cancellation occurs less than 4 (four) business hours prior to
                the appointment.
              </li>
              <li>
                If the BEONE AI technician offers a solution the Client does not
                wish to use, the Client will be charged for the time spent on
                site up to that point.
              </li>
              <li>
                If the BEONE AI technician is prevented from resolving a problem
                because the Client does not possess the appropriate software
                disks, drivers, or product serial numbers, the Client will be
                charged for the time spent up to that point.
              </li>
              <li>
                If the BEONE AI technician diagnoses a fault with a Client’s
                internet service provider, even when the internet service
                provider denies any fault, the Client will be charged the
                standard rates for the time spent on site up to that point.
              </li>
              <li>In the event either Party (“the Defaulting Party”):</li>
              <li>
                allows judgment that has been taken against it to remain unpaid,
                or takes no steps to rescind or appeal the judgment for more
                than 7 (seven) days; or
              </li>
              <li>
                is sequestrated or liquidated, or performs an act of insolvency
                in terms of the Insolvency Act 24 of 1936; or
              </li>
              <li>enters into a compromise with any of its creditors; or</li>
              <li>
                has been placed, or application has been made to be placed under
                business rescue proceeding in terms of the Companies Act, 71 of
                2008;
              </li>
              <li>
                being a natural person, dies, or being a juristic person
                undergoes a material restructure; then the other Party (“the
                Aggrieved Party”) may without affecting any of its other rights
                proceed with the termination in writing of the Agreement with
                immediate effect, and the Defaulting Party will return, at its
                costs, to the Aggrieved Party within 7 (seven) days of receipt
                of such written termination all documentation and/or property in
                its possession belonging to the Aggrieved Party and both Parties
                will make payment of any amount due and owing to the other Party
                within 7 (seven) days of receipt of such written termination
                arising out of the Agreement, without prejudice to any rights
                which either Party may have in terms hereof and at law.
              </li>
              <li>
                BEONE AI reserves the right to refuse the provision of any
                services for any reason including, but not limited to, the
                presence of unlicensed or illegal software and or material of an
                obscene or pornographic nature on a Client’s computer. If for
                such reason, BEONE AI terminates the Services, the Client shall
                be liable for any charges incurred concerning the time spent on
                site by the BEONE AI technician.
              </li>
              <li>
                All account details, codes, and passwords will only be provided
                upon settlement of the account.
              </li>
              <li>
                In the event of any dissatisfaction with the Services provided
                by BEONE AI, the Client should immediately contact BEONE AI on
                010 013 0089 or admin@BEONE AI.co.za. BEONE AI will make an
                appointment for a return visit by the designated technician. The
                technician will endeavour to rectify the problem. If the cause
                of dissatisfaction was due to a matter beyond the control of
                BEONE AI, the Client shall pay for the additional time and
                travel incurred at BEONE AI’s normal rates. If the problem arose
                directly as a result of BEONE AI’s previous attendance, no
                further charge shall be made.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledSeperateHeading title="Fair use policy:" />
        <StyledComponent title="In terms of BEONE AI’s unlimited remote and telephonic support contracts">
          <p className="mb-16">
            Although BEONE AI is able to provide unlimited remote and telephonic
            support for each user at the Client, there is a fair use limit of 3
            separate tickets per device, per calendar month for unrelated
            incidents. For example, if BEONE AI needs to repair the same
            computer for an ongoing problem or a particular repair is expected
            to take a long time, the support is unlimited (subject to all other
            conditions), but should a single computer suffer varied unrelated
            problems, the support is limited to three single tickets within a
            month. This is because numerous unrelated problems on a device are
            symptomatic of hardware failure and BEONE AI will make a
            recommendation to replace or repair subject to warranty terms.
          </p>
          <p className="mb-16">
            BEONE AI’s technicians are experienced in removing and dealing with
            viruses, malware, and spyware, but given their very nature (and the
            varying causes of infection), BEONE AI is unable to provide any
            extended warranty for on-site or off-site support. BEONE AI’s
            technicians will typically perform all the necessary tests to
            diagnose and remove any infections as best as possible, causing as
            little disruption to the Client, the Client’s computer, and
            applications as possible but, given that the repair options vary
            from “do nothing” to “wipe the computer”, the varying repairs can
            have equally varying results. Additionally, in some instances, virus
            and malware programs are designed to cause as much havoc when
            removed as when in place, meaning the removal can often leave a
            computer in a more fragile state than when infected. In these
            instances, BEONE AI will continue to provide any support as required
            but cannot be held responsible for damages caused as a result of any
            attempted repairs. The Client should ensure they have a working,
            reliable backup of all their data and important information before
            requesting any support, resulting in potentially ‘aggressive’ repair
            solutions.
          </p>
          <p className="mb-16">
            The following exclusions are not counted as unlimited and will be
            quoted and invoiced separately:
          </p>
          <p className="mb-16">
            <ul className="pl-4 list-disc">
              <li>
                Training sessions (planned, groups or anything that requires
                more than 10 minutes dedicated instruction in total);
              </li>
              <li>
                New hardware or third-party software installations (if a new
                server, workstation, software rollout, or IT system is required,
                a pre-approved quote will be required before any work is carried
                out);
              </li>
              <li>
                Repeat faults as a direct result of end-users ignoring
                recommendations provided by BEONE AI;
              </li>
              <li>
                Faults with third-party software that BEONE AI is not able or
                authorized to work with;
              </li>
              <li>
                General misuse by the Client of the system, hardware, or
                software; and/or
              </li>
              <li>
                Any projects which fall outside of normal maintenance and
                support. Each such project will be quoted and approved by the
                Client separately.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Breach">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                In the event either Party fails to comply with any terms and
                conditions, then the Party who has been aggrieved shall give
                written notice to the other Party calling on it to remedy any
                breach of the Agreement. If the other Party fails to remedy the
                breach within 20 (twenty) business days of receipt of the
                notice, then the Aggrieved Party may elect to cancel the
                Agreement or to demand specific performance, without prejudice
                to its rights to claim damages and any other rights it may have
                in the law.
              </li>
              <li>
                The Client shall be liable to BEONE AI for all legal expenses on
                the attorney-and-client scale incurred by BEONE AI in the event
                of (a) any default by the Client or (b) any litigation regarding
                the validity and enforceability of the Agreement. The Client
                shall also be liable for any tracing, collection fees, or
                valuation fees incurred as well as for any costs or security
                that BEONE AI demands to enforce the Agreement.
              </li>
              <li>
                Should payment of the final amount not be paid on the release of
                the goods or services, the Client agrees that interest in the
                amount of 2% per month may be charged until payment of the total
                outstanding amount in full.
              </li>
              <li>
                The Aggrieved Party’s attorney or debt collector (as the case
                may be) shall on receiving a payment from the Infringing Party,
                have the right to allocate such payment firstly towards
                disbursements incurred by the attorney or debt collector,
                secondly towards fees to which the attorney or debt collector is
                legally entitled, thirdly towards interest due to the Aggrieved
                Party and finally towards the capital amount due to the
                Aggrieved Party. 
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Protection of Personal Information (POPIA)">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                In order for BEONE AI to perform its obligations under this
                Agreement, the Client may need to disclose to BEONE AI certain
                of the Client’s or third parties’ Personal Information (as
                defined in the Protection of Personal Information Act, No. 4 of
                2013 (“POPIA”), as amended).
              </li>
              <li>
                In processing the Personal Information, BEONE AI is required to
                develop processes, implement measures and controls compliant
                with POPIA.
              </li>
              <li>
                BEONE AI agrees that it shall not process the Personal
                Information whether for its account or for any reason or purpose
                whatsoever unless such is being done in compliance with POPIA
              </li>
              <li>
                BEONE AI warrants that it has in place appropriate security
                measures, both technical and organizational, against the
                unlawful or unauthorized processing of information.
              </li>
              <li>
                The Client is provided with the BEONE AI’s Privacy Policy and
                Consent Form for signature together with this Agreement in
                accordance with POPIA, attached herewith as Annexure A.
              </li>
              <li>
                This clause shall survive the termination of the Agreement.
              </li>
            </ul>
          </p>
        </StyledComponent>
        <StyledComponent title="Non-Solicitation">
          <p>
            <ul className="pl-4 list-disc">
              <li>
                During the term of the agreement and for a period of 12 months
                after its termination, the Client agrees not to directly or
                indirectly solicit or hire any employee or contractor of BEONE
                AI without BEONE AI’s prior written consent. This clause also
                prohibits the Client from inducing any Company employee or
                contractor to terminate their employment or contract with the
                Client. The Client acknowledges that BEONE AI invests
                significant time and resources in recruiting and training its
                employees and contractors, and soliciting or hiring such
                individuals would cause irreparable harm to the Company's
                business.
              </li>
              <li>
                The parties agree that a breach of the Non-Solicitation Clause
                will result in irreparable harm to the Company that cannot be
                adequately compensated by monetary damages. Accordingly, BEONE
                AI shall be entitled to seek injunctive relief and any other
                available equitable remedies in addition to any other remedies
                available at law or in equity.
              </li>
              <li>
                The Client agrees to pay all costs and expenses, including
                reasonable attorneys' fees, incurred by BEONE AI in enforcing
                this provision.
              </li>
            </ul>
          </p>
        </StyledComponent>

        <StyledComponent title="General">
          <p className="mb-16">
            <ul className="pl-4 list-disc">
              <li>
                The terms and conditions supersede any terms and conditions
                received prior to the order received.
              </li>
              <li>
                BEONE AI will maintain the confidentiality of the Client’s files
                and/or data as required in terms of the law. BEONE AI further
                undertakes not to provide the Client’s information to any third
                party, save in the event that it is lawfully required to do so.
              </li>
              <li>
                The Client agrees to these terms and conditions between the
                Client and BEONE AI and that no alterations or additions to
                these terms and conditions may be affected unless agreed to by
                both Parties, reduced to writing and signed by the Client and a
                duly authorized representative of BEONE AI.
              </li>
              <li>
                No extension of time, relaxation, or indulgence granted by any
                Party to another shall be deemed to be a waiver or tacit
                amendment of that Party’s rights in terms hereof, nor shall any
                such relaxation or indulgence be deemed to be a novation or
                waiver of the terms and conditions.
              </li>
              <li>
                Each provision of these terms and conditions is severable from
                the other provisions. Should any provision be found to be
                invalid or unenforceable for any reason, the remaining
                provisions of these terms and conditions shall nevertheless
                remain binding and continue with full force and effect.
              </li>
              <li>
                Any order is subject to cancellation without penalty or
                liability by BEONE AI due to force majeure from any cause beyond
                the control of BEONE AI, including (without restricting this
                clause to these instances): inability to secure labour, power
                (load shedding), materials, or supplies, or by reason of an act
                of God, war, civil disturbance, riot, state of emergency,
                strike, lockout, or other labour disputes, fire, flood, drought,
                lockdown, national or international pandemic, government action
                or legislation. BEONE AI will provide the Client with reasonable
                written notification in this event.
              </li>
              <li>
                The Client hereby consents that the Parties shall have the right
                to institute any legal action in the Magistrate’s Courts. The
                Client agrees that BEONE AI may approach the High Court in the
                event that the matter is out of the jurisdiction of the
                Magistrates’ Courts.  These South African courts shall have
                exclusive jurisdiction in any litigation between the Parties
                arising from whatsoever source.
              </li>
              <li>
                The Client may not delegate, assign, or subcontract any of the
                activities included in these terms and conditions or
                instructions without the written consent of the authorized
                representative of BEONE AI.
              </li>
              <li>
                These terms and conditions are subject to the laws of South
                Africa.
              </li>
              <li>
                The signatory/ies is/are hereby bound as surety/ies for and
                co-principal debtor/s in solidum with the Client for the due
                fulfilment and performance by the Client of all its obligations
                in terms of or arising out of any agreement, including any
                extension, renewal or amendment thereof, that is entered into
                between the Parties on the strength of these terms and
                conditions and shall remain in force until all such obligations
                have been discharged in full.
              </li>
            </ul>
          </p>
        </StyledComponent>
      </div>
    </>
  );
};

export default TermsAndConditions;
